import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/index.css';

function Index() {
  const [allowance, setAllowance] = useState(null);
  useEffect(() => {
    async function getAllowance() {
      const res = await fetch('/api/allowances');
      const newAllowance = await res.text();
      setAllowance(newAllowance);
    }
    getAllowance();
  }, []);
  return (
    <main>
      <Helmet>
        <title>National Joint Council's (unofficial) REST API</title>
      </Helmet>
      <h1>National Joint Council's (unofficial) REST API</h1>
      <p>
        This project aims to provie a REST API for the meals and incidentals allowances provided by the National Joint Council (NJC) for travel.
        This REST endpoint gives users the ability to query the rates programmatically from Excel or other software, for the purpose of cost estimates.
      </p>
      <p><strong>Features:</strong>
        <ul>
            <li>free, thanks to{' '}
                <a
                  href="https://vercel.com/docs"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Vercel
                </a>!
            </li>
            <li>rates are based on the most recent tables from the official NJC website, refreshed at every request</li>
            <li>overrides the country currency with the allowance-specific currency if one is given (e.g. Azores' currency is euros; but incidentals are listed as CAD 17.30)</li>
            <li>correctly handles countries with a single rate, regardless of city requested</li>
            <li>retrieves the rate for city "Other", if available, when requesting an unlisted city</li>
            <li>features case and locale insentive country/city matching (e.g. bogota works for Bogotá)</li>
            <li>supports partial country name matching - e.g. Aruba works for Aruba (Kingdom of the Netherlands)</li>
            <li>supports country names expressed in ISO-3166 digraphs and trigraphs (e.g. UK, CA, CAN, GBR) (using the{' '}
            <a
              href="http://geonames.org"
              target="_blank"
              rel="noreferrer noopener"
            >
            geonames.org
            </a>{' '}country data set)</li>
            <li>supports {' '}<code>json</code> or <code>csv</code>{' '}formatted output</li>
            <li>merges rates from both{' '}
            <a
              href="https://www.njc-cnm.gc.ca/directive/d10/v238/s659/en"
              target="_blank"
              rel="noreferrer noopener"
            >
            Appendix C (Canada and continental United States)
            </a>
            {' '}and{' '}
            <a
              href="https://www.njc-cnm.gc.ca/directive/app_d.php?lang=en"
              target="_blank"
              rel="noreferrer noopener"
            >
            Appendix D (international) tables
            </a>
            </li>
            <li>leverages javascript promises to perform GET requests and fetch appendices C & D simultaneously, to improve performance</li>
            <li>for Canada and the USA, determines the city's region (territory/state) to return specific regional rates (using the{' '}
            <a
              href="http://geonames.org"
              target="_blank"
              rel="noreferrer noopener"
            >
            geonames.org
            </a>{' '}API) (Hawaii, Yukon, Northwest Territories, Nunavut, Alaska)</li>
            <li>overcomes the unterminated {`<strong>`} tags in the appendix D HTML source</li>
            <li><strong>limitation: provides only <strong>commercial</strong> daily rates for days 1-30</strong>{' '}(rates for longer stays can be computed using percentages)</li>
        </ul>
      </p>
      <br /><p>
        <strong>Note: please always verify the rates against the official National Joint Council{' '}
        <a
          href="https://www.njc-cnm.gc.ca/s3/en"
          target="_blank"
          rel="noreferrer noopener"
        >
          rate tables.
        </a>
        </strong>
      </p>
      <h2>
      API examples with sample output (truncated)
      </h2>
      <ul>
      <li>Full list in json format{' '}
        <a
          href="api/allowances"
          target="_blank"
          rel="noreferrer noopener"
        >
         https://njc-rest.ca/api/allowances 
        </a>
        <div class='example-output'>
        <pre>{`{ "results" : [
        {"country":"Afghanistan","currency":"CAD","city":"Kabul","breakfast":"*","lunch":"*","dinner":"*","meals":"*","incidentals":"17.30","total":"*"},
        {"country":"Afghanistan","currency":"CAD","city":"Kandahar","breakfast":"*","lunch":"*","dinner":"*","meals":"*","incidentals":"17.30","total":"*"},
        {"country":"Afghanistan","currency":"CAD","city":"Other","breakfast":"*","lunch":"*","dinner":"*","meals":"*","incidentals":"17.30","total":"*"},
        {"country":"Albania","currency":"EUR","city":"Tirana","breakfast":"9.75","lunch":"15.85","dinner":"22.50","meals":"48.10","incidentals":"15.39","total":"63.49"},
        {"country":"Albania","currency":"EUR","city":"Other","breakfast":"7.80","lunch":"12.68","dinner":"18.00","meals":"38.48","incidentals":"12.31","total":"50.79"},
        {"country":"Algeria","currency":"CAD","city":"Algiers","breakfast":"28.45","lunch":"52.30","dinner":"71.00","meals":"151.75","incidentals":"48.56","total":"200.31"},
        {"country":"Algeria","currency":"CAD","city":"Other","breakfast":"22.76","lunch":"41.84","dinner":"56.80","meals":"121.40","incidentals":"38.85","total":"160.25"},
        ...
] }`}</pre></div></li>
      <li>Full list in csv format{' '}
        <a
          href="api/allowances?format=csv"
          target="_blank"
          rel="noreferrer noopener"
        >
         https://njc-rest.ca/api/allowances?format=csv 
        </a>
        <div class='example-output'>
        <pre>{`
        country,currency,city,breakfast,lunch,dinner,meals,incidentals,total
        Afghanistan,CAD,Kabul,*,*,*,*,17.30,*
        Afghanistan,CAD,Kandahar,*,*,*,*,17.30,*
        Afghanistan,CAD,Other,*,*,*,*,17.30,*
        Albania,EUR,Tirana,9.75,15.85,22.50,48.10,15.39,63.49
        Albania,EUR,Other,7.80,12.68,18.00,38.48,12.31,50.79
        Algeria,CAD,Algiers,28.45,52.30,71.00,151.75,48.56,200.31
        Algeria,CAD,Other,22.76,41.84,56.80,121.40,38.85,160.25
        Andorra,CAD,Andorra la Vella,*,*,*,*,17.30,*
        Angola,CAD,Luanda,*,59.20,70.05,129.25,51.70,180.95
        ...
        `}</pre></div></li>
      <li>Country listing{' '}
        <a
          href="api/allowances?country=Australia"
          target="_blank"
          rel="noreferrer noopener"
        >
         https://njc-rest.ca/api/allowances?country=Australia 
        </a>
        <div class='example-output'>
        <pre>{`{ "results" : [
        {"country":"Australia","currency":"AUD","city":"Adelaide","breakfast":"32.15","lunch":"55.75","dinner":"79.15","meals":"167.05","incidentals":"53.46","total":"220.51"},
        {"country":"Australia","currency":"AUD","city":"Brisbane","breakfast":"34.50","lunch":"60.00","dinner":"88.15","meals":"182.65","incidentals":"58.45","total":"241.10"},
        {"country":"Australia","currency":"AUD","city":"Canberra","breakfast":"28.60","lunch":"55.40","dinner":"69.55","meals":"153.55","incidentals":"49.14","total":"202.69"},
        {"country":"Australia","currency":"AUD","city":"Hobart","breakfast":"27.00","lunch":"54.00","dinner":"74.25","meals":"155.25","incidentals":"49.68","total":"204.93"},
        {"country":"Australia","currency":"AUD","city":"Melbourne","breakfast":"32.00","lunch":"61.15","dinner":"85.65","meals":"178.80","incidentals":"57.22","total":"236.02"},
        {"country":"Australia","currency":"AUD","city":"Perth","breakfast":"33.25","lunch":"59.70","dinner":"82.10","meals":"175.05","incidentals":"56.02","total":"231.07"},
        {"country":"Australia","currency":"AUD","city":"Sydney","breakfast":"35.00","lunch":"66.45","dinner":"90.80","meals":"192.25","incidentals":"61.52","total":"253.77"},
        {"country":"Australia","currency":"AUD","city":"Other","breakfast":"22.88","lunch":"44.32","dinner":"55.64","meals":"122.84","incidentals":"39.31","total":"162.15"}
] } `}</pre></div></li>
      <li>Specific city{' '}
        <a
          href="api/allowances?city=bogota"
          target="_blank"
          rel="noreferrer noopener"
        >
         https://njc-rest.ca/api/allowances?city=bogota
        </a><br/>(only works for cities specifically listed in Appendix D)
        <div class='example-output'>
        <pre>{`{ "results" : [
        {"country":"Colombia","currency":"COP","city":"Bogotá","breakfast":"40,700.00","lunch":"71,300.00","dinner":"93,700.00","meals":"205,700.00","incidentals":"65,824.00","total":"271,524.00"}
] }`}</pre></div></li>
      <li>Country with single rate; when an unlisted city requested{' '}
        <a
          href="api/allowances?country=Armenia&city=Talin"
          target="_blank"
          rel="noreferrer noopener"
        >
         https://njc-rest.ca/api/allowances?country=Armenia&city=Talin
        </a>
        <div class='example-output'>
        <pre>{`{ "results" : [
        {"country":"Armenia","currency":"USD","city":"Yerevan","breakfast":"15.65","lunch":"21.15","dinner":"32.15","meals":"68.95","incidentals":"22.06","total":"91.01"}
] } `}</pre></div></li>
      <li>Country with "Other" rates, with an unlisted city name{' '}
        <a
          href="api/allowances?country=Australia&city=Cairn"
          target="_blank"
          rel="noreferrer noopener"
        >
         https://njc-rest.ca/api/allowances?country=Australia&city=Cairn
        </a>
        <div class='example-output'>
        <pre>{`{ "results" :[
        {"country":"Australia","currency":"AUD","city":"Other","breakfast":"22.88","lunch":"44.32","dinner":"55.64","meals":"122.84","incidentals":"39.31","total":"162.15"}
] }`}</pre></div></li>
      <li>Canada (using ISO-3166 digraph) with a city matching a territory with specific rate{' '}
        <a
          href="api/allowances?country=CA&city=Yellowknife"
          target="_blank"
          rel="noreferrer noopener"
        >
         https://njc-rest.ca/api/allowances?country=CA&city=Yellowknife
        </a>
        <div class='example-output'>
        <pre>{`{ "results" : [
{"country":"Canada (Northwest Territories)","currency":"CAD","city":"Other","breakfast":"24.85","lunch":"30.15","dinner":"64.50","meals":"119.50","incidentals":"17.30","total":"136.80"}
] }`}</pre></div></li>
      <li>USA (using ISO-3166 trigraph) with a city matching a state with specific rate{' '}
        <a
          href="api/allowances?country=usa&city=anchorage"
          target="_blank"
          rel="noreferrer noopener"
        >
         https://njc-rest.ca/api/allowances?country=usa&city=anchorage
        </a>
        <div class='example-output'>
        <pre>{`{ "results" : [
{"country":"United States of America (Alaska)","currency":"USD","city":"Other","breakfast":"23.05","lunch":"21.15","dinner":"61.00","meals":"105.20","incidentals":"17.30","total":"122.50"}
] }`}</pre></div></li>
        </ul>
      <h3>
        Deployed with{' '}
        <a
          href="https://www.gatsbyjs.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          Gatsby
        </a>{' '}on{' '}
        <a
          href="https://vercel.com/docs"
          target="_blank"
          rel="noreferrer noopener"
        >
          Vercel
        </a>
        !
      </h3>
      <h4>with the help of{' '}
        <a
          href="https://reactjs.org/"
          target="_blank"
          rel="noreferrer noopener"
        >
         react.js
        </a>,{' '}
        <a
          href="https://cheerio.js.org"
          target="_blank"
          rel="noreferrer noopener"
        >
        cheerio.js
        </a>,{' '}
        <a
          href="https://github.com/axios/axios"
          target="_blank"
          rel="noreferrer noopener"
        >
        axios
        </a> and{' '}
        <a
          href="http://geonames.org"
          target="_blank"
          rel="noreferrer noopener"
        >
        geonames.org
        </a>
      </h4>
    </main>
  );
}

export default Index;
